$enable-cssgrid: true;

@import "~bootstrap";

html {
  scroll-behavior: smooth;
}

main {
  @extend .mb-5;
}

.ak-actions {
  @extend .d-flex;
  @extend .flex-row;
  @extend .justify-content-end;
  .btn {
    visibility: hidden;
  }
}

.ak-page {


  .ak-page-content {
    @extend .p-3;
    @extend .border;
    @extend .border-2;
    @extend .border-white;

    &:hover {
      border-left-color: rgba($primary, 0.5) !important;
      @extend .bg-primary;
      @extend .bg-opacity-10;

      .ak-actions-page .btn {
        visibility: visible;
      }
    }
  }
}

.ak-breadcrumb-entry {
  @extend .p-3;
  @extend .border;
  @extend .border-2;
  @extend .border-white;

  &:hover {
    border-left-color: rgba($primary, 0.5) !important;
    @extend .bg-primary;
    @extend .bg-opacity-10;
  }
}

.ak-default-menu-entry {
  @extend .p-3;
  @extend .border;
  @extend .border-2;
  @extend .border-white;

  &:hover {
    border-left-color: rgba($primary, 0.5) !important;
    @extend .bg-primary;
    @extend .bg-opacity-10;
  }
}

.ak-menu-entry {
  @extend .border;
  @extend .border-4;
  border-color: transparent !important;

  &:hover {
    border-left-color: rgba($primary, 0.5) !important;
  }
}

.ak-menu-entry-content {
  @extend .p-3;

  &:hover {
    @extend .bg-primary;
    @extend .bg-opacity-10;

    .ak-actions-menu-entry .btn {
      visibility: visible;
    }
  }
}

.ak-sub-menu-entry-content {
  @extend .p-3;

  &:hover {
    @extend .bg-primary;
    @extend .bg-opacity-10;

    .ak-actions-sub-menu-entry .btn {
      visibility: visible;
    }
  }
}

.ak-sub-sub-menu-entry-content {
  @extend .p-3;

  &:hover {
    @extend .bg-primary;
    @extend .bg-opacity-10;

    .ak-actions-sub-sub-menu-entry .btn {
      visibility: visible;
    }
  }
}

.is-editable {
  transition: 250ms ease-out;
  transition-property: color;
}

.is-locked {
  transition: 250ms ease-in;

  opacity: 0.25;
}

.is-unpublished {
  @extend .bg-secondary;
  @extend .bg-opacity-10;
}

.ak-section {
  @extend .border;
  @extend .border-2;
  border-color: transparent !important;

  &:hover {
    border-left-color: rgba($primary, 0.5) !important;
  }

  .ak-section-content {
    @extend .p-3;

    &:hover {
      @extend .bg-primary;
      @extend .bg-opacity-10;

      .ak-actions-section .btn {
        visibility: visible;
      }
    }
  }
}

.ak-item {
  @extend .border;
  @extend .border-2;
  background-color: transparent;
  border-color: transparent !important;

  &:hover {
    @extend .bg-primary;
    @extend .bg-opacity-10;

    border-left-color: rgba($primary, 0.5) !important;

    .ak-actions-item .btn {
      visibility: visible;
    }
  }

  .ak-item-content {
    @extend .p-3;
  }
}

table {
  @extend .table;
}

.image-picker {

  .header {
    @extend .mt-5;
    @extend .mb-5;
  }

  @extend .container;
}

.image-picker-grid {
  height: 70vh;
  overflow-y: auto;
}

.image-picker-grid-row {
  @extend .row;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;

  .col {
    width: 235px;

    @include media-breakpoint-up(md) {
      width: 157px;
    }
    @include media-breakpoint-up(lg) {
      width: 154px;
    }
    @include media-breakpoint-up(xl) {
      width: 185px;
    }
  }
}
